import React from "react";
import { Header } from "antd/es/layout/layout";
import dotMenu from "../assets/images/icon/dotsmenu.svg";
import LiveCalls from "../assets/images/icon/Live-calls.svg";
import CallCenter from "../assets/images/icon/Call-Center.svg";
import SMS from "../assets/images/icon/SMS.svg";
import Contacts from "../assets/images/icon/Contacts.svg";
import PBX from "../assets/images/icon/PBX-Portal.svg";
import logo from "../assets/images/icon/dataphone-header.svg";
import report from "../assets/images/icon/Reports.svg";
import { Avatar, Flex, Popover, Typography } from "../../node_modules/antd";
import { Dropdown } from "antd";
import { useState } from "react";
import MenuItem from "./Menu";
import { usePermissions } from "../hooks/permissions";
const { Text } = Typography;
const avatarStyle = {
    verticalAlign: "middle",
    color: "black",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "capitalize",
};
const dotMenuStyle = {
    border: "1px solid white",
    borderRadius: "25px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    margin: "auto",
    padding: "5px",
};
const HeaderStyle = {
    background: "#130d38",
    padding: "15px",
};
const HeaderContentStyle = { height: "100%" };
const HeaderMenu = { gap: "20px" };
const HeaderNameStyle = {
    color: "white",
    margin: "auto",
    textTransform: "capitalize",
};
const headerLogo = {
    cursor: "pointer",
};
export const LayoutHeader = ({ name, logout, navigate, permissions }) => {
    const { hasPermission } = usePermissions({
        userPermissions: permissions,
    });
    const items = [
        {
            label: "Logout",
            key: "0",
            onClick: () => {
                logout();
            },
        },
    ];
    const [navigationItems, setNavigationItems] = useState(items);
    const dotMenuContent = (React.createElement(Flex, { vertical: true },
        React.createElement(Flex, null,
            React.createElement(MenuItem, { imgSrc: LiveCalls, altText: "LiveCalls", text: "Live Calls", url: process.env.REACT_APP_LIVE_CALL_URL ? process.env.REACT_APP_LIVE_CALL_URL : null, permissionKey: "web-applications", permissionValue: ["teledash:any"], hasPermission: hasPermission }),
            React.createElement(MenuItem, { imgSrc: CallCenter, altText: "CallCenter", text: "Call center", url: process.env.REACT_APP_LIVE_CALL_URL ? `${process.env.REACT_APP_LIVE_CALL_URL}/call-center` : null, permissionKey: "web-applications", permissionValue: ["teledash:any"], hasPermission: hasPermission }),
            React.createElement(MenuItem, { imgSrc: SMS, altText: "SMS", text: "SMS", url: process.env.REACT_APP_SMS_URL ? process.env.REACT_APP_SMS_URL : null, permissionKey: "web-applications", permissionValue: ["sms:any"], hasPermission: null })),
        React.createElement(Flex, null,
            React.createElement(MenuItem, { imgSrc: Contacts, altText: "Contacts", text: "Contacts", url: process.env.REACT_APP_CONTACTS_URL ? process.env.REACT_APP_CONTACTS_URL : null, permissionKey: "web-applications", permissionValue: ["contact:any"], hasPermission: null }),
            React.createElement(MenuItem, { imgSrc: PBX, altText: "PBX", text: "PBX Portals", url: process.env.REACT_APP_PBX_URL ? process.env.REACT_APP_PBX_URL : null, permissionKey: "web-applications", permissionValue: ["pbx:any"], hasPermission: null }),
            React.createElement(MenuItem, { imgSrc: report, altText: "Reports", text: "Reports", url: process.env.REACT_APP_REPORTS_URL ? process.env.REACT_APP_REPORTS_URL : null, permissionKey: "web-applications", permissionValue: ["reports:any"], hasPermission: null }))));
    return (React.createElement(Header, { style: HeaderStyle },
        React.createElement(Flex, { align: "center", justify: "space-between", style: HeaderContentStyle },
            React.createElement("img", { style: headerLogo, src: logo, alt: "header logo", onClick: () => navigate("/") }),
            React.createElement(Flex, { style: HeaderMenu },
                React.createElement(Popover, { placement: "bottom", content: dotMenuContent },
                    React.createElement("img", { style: dotMenuStyle, src: dotMenu, alt: "dot menu" })),
                React.createElement(Text, { style: HeaderNameStyle }, name),
                React.createElement(Dropdown, { menu: { items: navigationItems }, trigger: ["click"] },
                    React.createElement("a", { onClick: (e) => e.preventDefault() },
                        React.createElement(Avatar, { style: { ...avatarStyle, background: "rgb(229, 255, 204)" }, size: "large" }, name[0])))))));
};
