import { Button, Flex, Input, Typography } from "antd";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { RiContactsBook3Line } from "react-icons/ri";
import CategoriesTable from "../components/Tables/Categories";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { FaTags } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import ModalWrapper from "../components/Generic/modal";
import { CategoryForm } from "../components/Forms/Category";
import { useGetCategories } from "../hooks/apis/categories";

const inputSearchStyle: React.CSSProperties = {
  borderRadius: "25px",
  border: "none",
  padding: "10px",
  width: "200px",
  marginBottom: "10px",
};

const searchIconStyle: React.CSSProperties = {
  height: "20px",
  width: "20px",
  color: "#6b7280",
  cursor: "pointer",
};

const CategoriesList = () => {
  const { data: categories } = useSelector((state: RootState) => state.categories);

  const { isFetching : isLoading} = useGetCategories();

  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  return (
    <div style={{ padding: "10px" }}>
      {addCategoryModal && (
        <ModalWrapper title="Add Category" isModalOpen={addCategoryModal} setIsModalOpen={setAddCategoryModal}>
          <CategoryForm setIsModal={setAddCategoryModal} />
        </ModalWrapper>
      )}
      <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
        <Flex gap={"10px"} justify="center" align="center">
          <MdCategory color="blue" size={35} />
          <Typography.Title>{`Categories`}</Typography.Title>{" "}
        </Flex>
        {
          <Button
            type="primary"
            onClick={() => {
              setAddCategoryModal(true);
            }}
          >
            Add Category
          </Button>
        }
      </Flex>
      <Input
        style={{
          ...inputSearchStyle,
        }}
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        size="middle"
        placeholder="Search"
        prefix={<IoSearch style={searchIconStyle} />}
      />
      <CategoriesTable categories={categories} searchInput={searchInput} loading={isLoading} />
    </div>
  );
};

export default CategoriesList;
