import { api } from "@data-phone/react-resource-server-auth";
import { useMutation, useQuery } from "react-query";
import { AppDispatch } from "../../redux";
import { useDispatch } from "react-redux";
import { addTagSlice, setTagsSlice, Tag } from "../../redux/slices/tags.slice";

const getTagsByCategoryApi = async () => {
  const response = await api.get(`
        ${process.env.REACT_APP_CONTACT_API_URL}/tags
        `);

  return response.data;
};

const addTagApi = async (data: Partial<Tag>) => {
  const response = await api.post(`${process.env.REACT_APP_CONTACT_API_URL}/tags`, data);
  return response.data;
};

const updateTagApi = async (name: string, data: Partial<Tag>) => {
  const response = await api.put(`${process.env.REACT_APP_CONTACT_API_URL}/tags/${name}`, data);
  return response.data;
};

const deleteTagApi = async (name: string) => {
  const response = await api.delete(`${process.env.REACT_APP_CONTACT_API_URL}/tags/${name}`);
  return response.data;
};

export const useGetTagsByCategory = () => {
  const dispatch: AppDispatch = useDispatch();

  return useQuery<Tag[], Error>("tags", () => getTagsByCategoryApi(), {
    enabled: false,
    onSuccess(data) {
      dispatch(setTagsSlice(data));
    },
  });
};

export const useAddTag = () => {
  return useMutation(addTagApi);
};

export const useUpdateTag = () => {
  return useMutation((tag: { name: string; data: Partial<Tag> }) => updateTagApi(tag.name, tag.data));
};

export const useDeleteTag = () => {
  return useMutation(deleteTagApi);
};
