import React from "react";
import { Flex, Typography, Popover } from "antd";
const { Text } = Typography;
const TextStyle = {
    color: "rgb(119, 117, 135)",
};
const dotMenuIconStyle = {
    borderRadius: "5px",
    cursor: "pointer",
    padding: "4px",
    width: "80px",
};
const MenuItem = ({ imgSrc, altText, text, url, permissionKey, permissionValue, hasPermission }) => {
    const isAllowed = hasPermission ? hasPermission(permissionKey, permissionValue) : true;
    return (React.createElement(Popover, { content: isAllowed ? text : "Permission denied contact admin" },
        React.createElement(Flex, { style: isAllowed ? { ...dotMenuIconStyle } : { ...dotMenuIconStyle, cursor: "not-allowed" }, onClick: () => {
                if (!isAllowed || !url)
                    return;
                isAllowed ? (window.location.href = url) : null;
            }, vertical: true, align: "center", className: "dot-menu-icons" },
            React.createElement("img", { style: isAllowed ? {} : { filter: "grayscale(1)" }, src: imgSrc, alt: altText }),
            React.createElement(Text, { style: TextStyle }, text))));
};
export default MenuItem;
