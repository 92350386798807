import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TagCategory {
    name: string
    account_id: string
    description: string
}

export interface TagCategoriesState {
    data: TagCategory[]
}

const initialState: TagCategoriesState = {
    data: [],
};

export const tagCategoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setTagCategoriesSlice: (state, action: PayloadAction<TagCategory[]>) => {
            state.data = action.payload;
        },
        addTagCategorySlice: (state, action: PayloadAction<TagCategory>) => {
            state.data = [...state.data, action.payload];
        },
        updateTagCategorySlice: (state, action: PayloadAction<TagCategory>) => {
            state.data = state.data.map((category) => {
                if (category.name === action.payload.name) {
                    return action.payload;
                }
                return category;    
            });
        },
        deleteTagCategorySlicce: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((category) => category.name !== action.payload);
        },
    },
});

export const { setTagCategoriesSlice, addTagCategorySlice, updateTagCategorySlice, deleteTagCategorySlicce } = tagCategoriesSlice.actions;

export default tagCategoriesSlice.reducer;