import React, { useCallback, useState } from "react";
import { Avatar, Button, Dropdown, Flex, Modal, Popover, Radio, Space, Spin, Table, Tag, Tooltip, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { CiEdit } from "react-icons/ci";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Contact, deleteContactSlice, updateContactSlice } from "../../redux/slices/contacts.slice";
import { formatDateToTime, formatPhoneNumber, generateNameSpaceColor } from "../../utils";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import { AddContactForm } from "@data-phone/react-generic";
import ModalWrapper from "../Generic/modal";
import { useDeleteContact, useUpdateContact } from "../../hooks/apis/contacts";
import { useMessageToast } from "../Layout/DefaultLayout";
import { MdOutlinePhone } from "react-icons/md";

const { confirm } = Modal;

type ColumnsType<T extends object> = TableProps<T>["columns"];

const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};

const ContactsTable = ({ contacts, loading, searchInput }: { contacts: Contact[]; loading: boolean; searchInput: string }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();

  const { data: tags } = useSelector((state: RootState) => state.tags);
  const { data: categories } = useSelector((state: RootState) => state.categories);

  const [editData, setEditData] = useState<Contact | null>(null);
  const [editContactModal, setEditContactModal] = useState(false);

  const { mutate: updateContact, isLoading: updateContactLoading } = useUpdateContact();
  const { mutate: deleteContact, isLoading: deleteContactLoading } = useDeleteContact();

  const editHandler = useCallback((record: Contact) => {
    setEditData(record);
    setEditContactModal(true);
  }, []);

  const onDeleteHandler = useCallback((id: string) => {
    showDeleteConfirm(id);
  }, []);

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure you want to delete this contact?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteContact(id, {
          onSuccess: () => {
            dispatch(deleteContactSlice(id));
            messageApi.success("Contact deleted successfully");
          },
        });
      },
    });
  };

  const columns: ColumnsType<Contact> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (_, { first_name, last_name, middle_name ,emails}) => (
        <Flex gap={"10px"} align={"center"}>
          <Avatar style={{ ...avatarStyle, background: generateNameSpaceColor(first_name ? `${first_name} ${middle_name} ${last_name}` : "") }}>{first_name ? first_name[0].toUpperCase() : ""}</Avatar>{" "}
          <Flex vertical>
            <Typography.Text strong>
              {first_name ? first_name : ""} {middle_name ? middle_name : ""} {last_name ? last_name : ""}
            </Typography.Text>
            <Typography.Text type="secondary">{emails.length > 0 ? emails[0].email : ""}</Typography.Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "PROVIDER",
      dataIndex: "provider_id",
      key: "provider_id",
      render: (_, { provider_id }) => <Tag>{provider_id ? provider_id : "Local"}</Tag>,
    },
    {
      title: "PHONE",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (_, { phones }) => (
        <Flex gap={"10px"} align={"center"}>
          {" "}
          <MdOutlinePhone color="blue" size={20} />
          <Typography.Text strong>{formatPhoneNumber(phones[0]?.phone_number)}</Typography.Text>
          {phones.length > 1 ? (
            <Popover
              content={phones.map((phone) => (
                <Flex gap={"10px"} vertical>
                  {" "}
                  <Typography.Text>{formatPhoneNumber(phone.phone_number)}</Typography.Text>
                </Flex>
              ))}
            >
              <HiOutlineDotsHorizontal  cursor={"pointer"}/>
            </Popover>
          ) : null}
        </Flex>
      ),
    },
    {
      title: "TAGS",
      dataIndex: "tags",
      key: "tags",
      filters: [
        ...categories.map((category) => {
          return {
            text: category.name,
            value: category.name,
            children: tags
              .filter((tag) => tag.category === category.name)
              .map((tag) => {
                return {
                  text: tag.name,
                  value: tag.name,
                };
              }),
          };
        }),
        {
          text: "Uncategorized",
          value: "uncategorized",
          children: tags
            .filter((tag) => !tag.name)
            .map((tag) => {
              return {
                text: tag.name,
                value: tag.name,
              };
            }),
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter(value, record) {
        return record.tags.some((tag) => tag.tag === value);
      },
      render: (_, { tags: contactTag }) => (
        <Flex>
          {contactTag
            ?.filter((tag, index) => index < 3)
            .map((tag) => {
              return (
                <Tag className="text-ellipsis" color="blue">
                  <Tooltip title={tags.find((t) => t.name === tag.tag)?.name}>
                    <Typography.Text>{tags.find((t) => t.name === tag.tag)?.name}</Typography.Text>
                  </Tooltip>
                </Tag>
              );
            })}
          {contactTag && contactTag?.length > 3 && (
            <Popover
              content={contactTag.map((tag) => {
                return (
                  <Tag color="blue">
                    <Tooltip title={tags.find((t) => t.name === tag.tag)?.name}>
                      <Typography.Text>{tags.find((t) => t.name === tag.tag)?.name}</Typography.Text>
                    </Tooltip>
                  </Tag>
                );
              })}
            >
              <Button style={{ height: "25px" }} icon={<HiOutlineDotsHorizontal />}></Button>
            </Popover>
          )}
        </Flex>
      ),
    },
    {
      title:"VISIBILITY",
      dataIndex:"is_private",
      key:"is_private",
      filters:[
        {
          text:"Private",
          value:true
        },
        {
          text:"Public",
          value:false
        }
      ],
      onFilter: (value, record) => record.is_private === value,

      render:(_, {is_private}) => <Tag color={is_private ? "gray" : "green"}>{is_private ? "Private" : "Public"}</Tag>
    },
    {
      title: "CREATED DATE",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => <Typography.Text strong>{dayjs(created_at).format("MM/DD/YYYY hh:mm A")}</Typography.Text>,
    },
    {
      title: "",
      key: "action",
      colSpan: 1,
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Edit",
                  onClick: () => editHandler(record),
                },
                {
                  key: "2",
                  label: "Delete",
                  onClick: () => onDeleteHandler(record.id as string),
                },
              ],
            }}
          >
            <Button style={{ border: "none" }} icon={<HiOutlineDotsVertical />}></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onFinish = (values: any) => {
    const data = {
      ...values,
      tags: values.tags.flatMap((tag) => {
        return {
          category:tag.category === "uncategorized" || !tag.category ? null : tag.category,
          tags: tag.tags.map((tag) => {
            return tag.value;
          })
        }
      }),
    }
    updateContact(
      data,
      {
        onSuccess: (data) => {
          setEditContactModal(false);
          dispatch(updateContactSlice(data));
          messageApi.success("Contact updated successfully");
        },
      }
    );
  };

  return (
    <div>
      {editContactModal && editData && (
        <ModalWrapper title="Edit Contact" isModalOpen={editContactModal} setIsModalOpen={setEditContactModal}>
          <AddContactForm
            defaultValues={editData}
            setIsModalOpen={setEditContactModal}
            tags={tags}
            isTagsFetching={false}
            onSubmit={(values: any) => {
              onFinish(values);
            }}
            categories={categories}
            isLoadingAddContact={updateContactLoading}
            messageApi={null}
          />
        </ModalWrapper>
      )}
      {/* {
                editUserModal && <EditUserModal setIsModalOpen={setEditUserModal} isModalOpen={editUserModal} editData={editData} />
            } */}
      <Table
        className="contact-table"
        bordered={false}
        loading={loading}
        scroll={{ y: 450 }}
        style={{ width: "100%", height: "500px" }}
        columns={columns}
        pagination={contacts.length > 10 && { position: ["bottomCenter"] }}
        dataSource={[...contacts].filter(
          (contact) =>
            `${contact.first_name || ""} ${contact.middle_name || ""} ${contact.last_name || ""}`.toLowerCase().includes(searchInput.toLowerCase()) ||
            `${contact.emails[0]?.email || ""}`.toLowerCase().includes(searchInput.toLowerCase()) ||
            contact.phones.some((phone) => `${phone.phone_number}`.includes(searchInput))
        )}
      />
    </div>
  );
};

export default ContactsTable;
