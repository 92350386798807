import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { api } from "@data-phone/react-resource-server-auth";
import { AppDispatch } from "../../redux";
import { Contact, setContacts } from "../../redux/slices/contacts.slice";

interface responseType {
  data: Contact[];
  status: string;
}


export interface contactQuery {
  updated_at: string;
}

const fetchContactsApi = async (query?: contactQuery) => {
  const response = await api.get(
    `${process.env.REACT_APP_CONTACT_API_URL}?${query?.updated_at ? `since=${query.updated_at}` : ""}`,
  );
  return response.data;
}

const importContacts = async (data: Contact[])=> {
  const response = await api.post(
    `${process.env.REACT_APP_CONTACT_API_URL}/import`,
    data
  );
  return response.data;
}

const addContactApi = async (data: any) => {
  const response: AxiosResponse<responseType> = await api.post(
    `${process.env.REACT_APP_CONTACT_API_URL}`,
    data
  );
  return response.data;
}

const deleteContactApi = async (id: string) => {
  const response: AxiosResponse<responseType> = await api.delete(
    `${process.env.REACT_APP_CONTACT_API_URL}/${id}`
  );
  return response.data;
}

const updateContactApi = async (data: any) => {
  const response: AxiosResponse<responseType> = await api.put(
    `${process.env.REACT_APP_CONTACT_API_URL}/${data.id}`,
    data
  );
  return response.data;
}

export const useGetContacts = (query?: contactQuery) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<any[], Error>(
    "contacts",
    () => fetchContactsApi(query),
    {
      enabled: false,
      onError: (error) => {
        console.log("failed", error);
      },
      onSuccess: (data) => {
        dispatch(setContacts(data))
      }
    }
  );
};

export const useAddContact = () => {
  return useMutation((data: any) => addContactApi(data));
}

export const useUpdateContact = () => {
  return useMutation((data: any) => updateContactApi(data));
}

export const useDeleteContact = () => {
  return useMutation((id: string) => deleteContactApi(id));
}

export const useImportContacts = () => {
  return useMutation((data: Contact[]) => importContacts(data));
}