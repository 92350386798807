import { Button, Flex, Input, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { FaTags } from "react-icons/fa";
import ModalWrapper from "../components/Generic/modal";
import { useSearchParams } from "react-router-dom";
import { useGetTagsByCategory } from "../hooks/apis/tags";
import { TagForm } from "../components/Forms/Tags";
import TagsTable from "../components/Tables/Tags";

const inputSearchStyle: React.CSSProperties = {
  borderRadius: "25px",
  border: "none",
  padding: "10px",
  width: "200px",
  marginBottom: "10px",
};

const searchIconStyle: React.CSSProperties = {
  height: "20px",
  width: "20px",
  color: "#6b7280",
  cursor: "pointer",
};

const TagsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: categories } = useSelector((state: RootState) => state.categories);
  const { data: tags } = useSelector((state: RootState) => state.tags);

  const [addTagModal, setAddTagModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(searchParams.get("category") || "all");

  const { refetch: getTags, isLoading: getTagsLoading } = useGetTagsByCategory();

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    setSearchParams({ category: selectedCategory });
  }, [selectedCategory]);

  return (
    <Flex vertical gap={"10px"} style={{ padding: "10px" }}>
      {addTagModal && (
        <ModalWrapper title="Add Tag" isModalOpen={addTagModal} setIsModalOpen={setAddTagModal}>
          <TagForm setIsModal={setAddTagModal} category={selectedCategory == "all" ? null : selectedCategory} />
        </ModalWrapper>
      )}
      <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
        <Flex gap={"10px"} justify="center" align="center">
          <FaTags color="blue" size={35} />
          <Typography.Title>{`Tags`}</Typography.Title>{" "}
        </Flex>
        {
          <Button
            icon={<FaTags />}
            type="primary"
            onClick={() => {
              setAddTagModal(true);
            }}
          >
            Add Tag
          </Button>
        }
      </Flex>

      <Select
        style={{ width: "200px" }}
        placeholder="Select Category"
        options={[
          {
            value: "all",
            label: "All",
          },
          ...categories.map((category) => ({
            value: category.name,
            label: category.name,
          })),
          {
            value: "uncategorized",
            label: "Uncategorized",
          },
        ]}
        onChange={(value) => setSelectedCategory(value)}
        defaultValue={searchParams.get("category_id") || "uncategorized"}
        value={selectedCategory}
      />
      <Input
        style={{
          ...inputSearchStyle,
        }}
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        size="middle"
        placeholder="Search"
        prefix={<IoSearch style={searchIconStyle} />}
      />
      <TagsTable
        tags={tags.filter((tag) => {
          if (selectedCategory === "all") {
            return true;
          } else if (selectedCategory === "uncategorized") {
            return tag.category === null;
          }
          return tag.category === selectedCategory;
        })}
        loading={getTagsLoading}
        searchInput={searchInput}
      />
    </Flex>
  );
};

export default TagsPage;
