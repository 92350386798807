import { Modal, Typography } from "antd";
import { IoClose } from "react-icons/io5";
const { Title } = Typography;

const ModalWrapper = ({
  title,
  isModalOpen,
  setIsModalOpen,
  children,
}: {
  isModalOpen: boolean;
  title: string;
  setIsModalOpen: Function;
  children: React.ReactNode;
}) => {
  return (
    <Modal
      styles={{
        header: { backgroundColor: "#130D38", padding: "10px" },
        content: {
          padding: "0px",
        },
      }}
      closeIcon={
        <IoClose
          style={{
            color: "white",
            borderRadius: "50%",
            border: "1px solid white",
            marginTop: "10px",
          }}
        />
      }
      footer={null}
      title={
        <Title style={{ color: "white", fontSize: "22px" }}>{title}</Title>
      }
      onCancel={() => setIsModalOpen(false)}
      open={isModalOpen}
    >
      {children}
    </Modal>
  );
};


export default ModalWrapper