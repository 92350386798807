import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface Tag {
    name: string
    category: string | null
    account_id: string
}

export interface TagState {
    data: Tag[]
}

const initialState: TagState = {
    data: []
}

export const tagsSlice = createSlice({
    name: "tags",
    initialState,
    reducers: {
        setTagsSlice: (state, action: PayloadAction<Tag[]>) => {

            action.payload.forEach((tag) => {
                const existingTag = state.data.find((t) => t.name === tag.name);
                if (!existingTag) {
                    state.data.push(tag);
                }
            })
        },
        addTagSlice: (state, action: PayloadAction<Tag>) => {
            console.log("payload", action.payload)
            state.data = [...state.data, action.payload];
        },
        updateTagSlice: (state, action: PayloadAction<{
            name: string
            data: Tag
        }>) => {
            state.data = state.data.map((tag) => {
                if (tag.name === action.payload.name) {
                    return action.payload.data
                }
                return tag;
            });
        },
        deleteTagSlice: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((tag) => tag.name !== action.payload);
        },

    },
});

export const { setTagsSlice, addTagSlice, updateTagSlice, deleteTagSlice } = tagsSlice.actions;

export default tagsSlice.reducer;