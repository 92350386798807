import { Col, Flex, Layout, Row } from "antd";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { RootState } from "../../redux";
import { LayoutHeader } from "@data-phone/react-generic";
import { usePermissions } from "../../hooks/permissions/usePermissions";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { useGetCategories } from "../../hooks/apis/categories";

const DefaultLayout = ({
    logout
}: {
    logout: () => void
}) => {
    const layoutStyle: React.CSSProperties = {
        height: "100vh",
        background: "#ffffff !important",
    };

    const user = useSelector((state: RootState) => state.user.data);

    const [collapsed, setCollapsed] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();
    const { hasPermission } = usePermissions();
    const navigate = useNavigate()
    const { refetch: getCategories } = useGetCategories()


    useEffect(() => {
        getCategories()
    }, [])



    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Rethink Sans",
                },
            }}
        >
            <Layout style={layoutStyle}>
                {contextHolder}
                <LayoutHeader permissions={user?.permissions} logout={logout} name={`${user?.firstName} ${user?.lastName}`}   navigate={navigate} />
                <Layout >
                    <Flex >
                        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                        <div style={{ width: collapsed ? "95%" : "87%" }}>
                            <Outlet context={{ messageApi } satisfies MessageInstance | any} />
                        </div>
                    </Flex>
                </Layout>
            </Layout>
        </ConfigProvider>
    );
};

export default DefaultLayout;

export function useMessageToast() {
    return useOutletContext<any>();
}