import { Button, Form, FormProps, Input } from "antd";
import { useEffect } from "react";
import { useMessageToast } from "../Layout/DefaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { addTagCategorySlice, TagCategory, updateTagCategorySlice } from "../../redux/slices/categories.slice";
import { useAddCategory, useUpdateCategory } from "../../hooks/apis/categories";
import { AppDispatch, RootState } from "../../redux";
import TextArea from "antd/es/input/TextArea";
import { updateContactSlice } from "../../redux/slices/contacts.slice";

export const CategoryForm = ({ setIsModal, editData }: { setIsModal: Function; editData?: TagCategory }) => {
  const dispatch: AppDispatch = useDispatch();
  const { data: contactCategories } = useSelector((state: RootState) => state.categories);

  const { mutate: addCategory, isLoading: isLoadingAdd } = useAddCategory();
  const { mutate: updateCategory, isLoading: isLoadingUpdate } = useUpdateCategory();
  const { messageApi } = useMessageToast();
  const [form] = Form.useForm();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
    }
  }, []);

  const onFinish: FormProps<TagCategory>["onFinish"] = (values) => {
    if (!editData) {
      if (contactCategories.some((category) => category.name === values.name)) {
        messageApi.error("Category already exists");
        return;
      }

      addCategory(
        {
          name: values.name,
          description: values.description,
        },
        {
          onSuccess: (data) => {
            dispatch(addTagCategorySlice(data));
            setIsModal(false);
            messageApi.success("Category added successfully");
          },
        }
      );
      return;
    } else {
      if (contactCategories.some((category) => category.name === values.name && category.name !== editData.name)) {
        messageApi.error("Category already exists");
        return;
      }
      updateCategory(
        {
          name: editData.name,
          data: {
            name: values.name,
            description: values.description,
          },
        },
        {
          onSuccess: (data) => {
            dispatch(updateTagCategorySlice(data));
            setIsModal(false);
            messageApi.success("Category updated successfully");
          },
        }
      );
    }
  };

  return (
    <Form style={{ padding: "10px" }} name="basic" form={form} labelCol={{ span: 4 }} onFinish={onFinish}>
      <Form.Item<TagCategory> name="name" label="Name" rules={[{ required: true, message: "Please input category name" }]}>
        <Input width={"80%"} placeholder="Category Name" />
      </Form.Item>
      <Form.Item<TagCategory> name="description" label="Description">
        <TextArea placeholder="Category Description" />
      </Form.Item>

      <Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType="submit" disabled={isLoadingAdd} loading={isLoadingAdd || isLoadingUpdate}>
          {editData ? "Update Category" : "Add Category"}
        </Button>
      </Form.Item>
    </Form>
  );
};
