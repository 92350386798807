import { Button, Drawer, Flex, Modal, Select, Typography, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import ModalWrapper from "../Generic/modal";
import { getBase64, parseCsv, phoneNumberValidate } from "../../utils";
import { useMessageToast } from "../Layout/DefaultLayout";
import { Contact, ImportContact, setContacts } from "../../redux/slices/contacts.slice";
import { useImportContacts } from "../../hooks/apis/contacts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { Tag } from "../../redux/slices/tags.slice";

const ImportContacts = ({ isModalOpen, setIsModalOpen }: { isModalOpen: boolean; setIsModalOpen: Function }) => {
  const { messageApi } = useMessageToast();
  const dispatch: AppDispatch = useDispatch();
  const { data: tags } = useSelector((state: RootState) => state.tags);
  const { mutate: importContactsHandler, isLoading: isLoadingImport } = useImportContacts();

  const [csvFile, setCsvFile] = useState<UploadFile[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [contactFields, setContactFields] = useState<{ [key: string]: string | null }>();
  const [parsedData, setParsedData] = useState<{ [key: string]: string | null }[]>([]);
  const fields = ["first_name", "last_name", "middle_name", "email", "phone", "address", "tag"];

  const onSelectedFileChange = async () => {
    if (csvFile && csvFile.length > 0) {
      const base64 = await getBase64(csvFile[0].originFileObj as RcFile);
      const parsedData = parseCsv(base64.split(",")[1], true);
      if (parsedData && parsedData.length > 0) {
        setCsvFields(Object.keys(parsedData[0]));
        setParsedData(parsedData);
        Object.keys(parsedData[0]).forEach((key) => {
          setContactFields((prev) => ({
            ...prev,
            [key]: null,
          }));
        });
      }
    } else {
      setCsvFields([]);
    }
  };

  useEffect(() => {
    onSelectedFileChange();
  }, [csvFile]);

  const categorize = (input: { [key: string]: string | null }) => {
    return Object.keys(input).reduce((acc: { [key: string]: string | string[] }, key) => {
      const value = input[key];
      if (value === "first_name" || value === "middle_name" || value === "last_name") {
        acc[value] = key;
      } else if (value === "email" || value === "phone" || value === "address" || value === "tag") {
        acc[value] && Array.isArray(acc[value]) ? (acc[value] as string[]).push(key) : (acc[value] = [key]);
      }

      return acc;
    }, {} as { [key: string]: string | string[] });
  };

  function structureTagsByCategory(array: string[], tags: Tag[]): { category: string | null; tag: string[] }[] {
    const categories = {};

    const tagMap = new Map(tags.map((item) => [item.name, item.category]));

    // Categorize tags
    array.forEach((tag) => {
      const category = tagMap.get(tag) || null;

      if (!categories[category]) {
        categories[category] = { category, tags: [] };
      }
      categories[category].tags.push(tag);
    });

    // Convert object to array
    return Object.values(categories);
  }

  const onFinish = () => {
    if (contactFields) {
      let newData = []
      const newFields = categorize(contactFields);

      if (!newFields.phone || newFields.phone.length === 0) {
        messageApi.open({
          type: "error",
          content: "Phone field is required",
        });
        return;
      }

      if (parsedData.length > 0) {
        newData = parsedData.map((data, index) => {
          let dt = {} as any;
          Object.keys(newFields).forEach((key) => {
            if (!Array.isArray(newFields[key]) && newFields[key] !== null) {
              if (data[newFields[key] as string] !== undefined && typeof data[newFields[key] as string] !== "object") {
                dt[key] = data[newFields[key] as string];
              }
            } else if (Array.isArray(newFields[key])) {
              if (data[newFields[key][0]] !== undefined) {
                dt[key] = (newFields[key] as string[])
                  .map((item: string) => {
                    return data[item];
                  })
                  .filter((item) => item !== undefined && item !== "");
              }
            }
          });

          return {
            first_name: dt.first_name || "",
            last_name: dt.last_name || "",
            middle_name: dt.middle_name || "",
            emails: dt.email || [],
            phones: dt.phone || [],
            addresses: dt.address || [],
            tags: dt.tag && Array.isArray(dt.tag) && dt.tag.length > 0 ? structureTagsByCategory(dt.tag, tags) : [],
            provider_id: null,
            is_private: false,
          };
        }) 
      }

      let invalidNumbers = [];

      newData.forEach((data) => {
        if (data.phones && data.phones.length > 0) {
          data.phones.forEach((phone) => {
            if (!phoneNumberValidate(phone as any)) {
              invalidNumbers.push(phone);
            }
          });
        }
      });

      if (invalidNumbers.length > newData.map((data) => data.phones).flat().length / 2) {
        messageApi.open({
          type: "error",
          content: "Too many invalid numbers",
        });
        return;
      }

      importContactsHandler(newData, {
        onSuccess: (data) => {
          messageApi.success("Contacts imported successfully");
          dispatch(setContacts(data));
          setIsModalOpen(false);
        },
      });
    }
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    beforeUpload: () => false,
    maxCount: 1,
    previewFile: getBase64 as any,
    onChange(info) {
      const { status } = info.file;
      setCsvFile(info.fileList);
      if (status !== "uploading") {
        if (info.file.type != "text/csv") {
          return messageApi.error("Only CSV files are allowed");
        }
      }
      if (status === "done") {
        setCsvFile(info.fileList);
      } else if (status === "error") {
        console.log("error", info.fileList);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <ModalWrapper setIsModalOpen={setIsModalOpen} title="Import Contacts" isModalOpen={isModalOpen}>
      <Flex gap={"10px"} style={{ padding: "10px" }} vertical>
        <Dragger fileList={csvFile} {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
        </Dragger>
        {csvFields.length > 0 && (
          <Flex gap={"10px"} style={{ padding: "10px" }} vertical>
            {csvFields.map((field, index) => (
              <Flex key={index} gap={"10px"} justify="space-between">
                <Typography.Text strong>{field}</Typography.Text>
                <Select
                  onClear={() => {
                    setContactFields({
                      ...contactFields,
                      [field]: null,
                    });
                  }}
                  allowClear
                  placeholder="Select Contact Field"
                  style={{
                    minWidth: "200px",
                  }}
                  onChange={(value) => {
                    setContactFields({
                      ...contactFields,
                      [field]: value,
                    });
                  }}
                  options={fields.map((key) => {
                    return { value: key, label: key };
                  })}
                />
              </Flex>
            ))}
          </Flex>
        )}
        <Flex justify="end" gap={"10px"}>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setCsvFile([]);
              setContactFields({});
              setCsvFields([]);
              setParsedData([]);
              
            }}
          >
            Cancel
          </Button>
          <Button loading={isLoadingImport} type="primary" onClick={onFinish}>
            Import
          </Button>
        </Flex>
      </Flex>
    </ModalWrapper>
  );
};

export default ImportContacts;
