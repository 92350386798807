import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";
import { RcFile } from "antd/es/upload";
import papa from "papaparse";

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

export function generateNameSpaceColor(namespace: string, lightness = 90) {
  const hash = hashCode(namespace);
  const hue = hash % 360; // Use hash code to determine the hue value (0 to 359)
  const color = `hsl(${hue}, 100%, ${lightness}%)`; // Set the lightness component for a lighter color
  return color;
}

export const formatPhoneNumber = (number: string) => {
  if (!number) return;

  if (isValidPhoneNumber(number, "US")) {
    const phoneNumber = parsePhoneNumber(number, "US");
    return phoneNumber?.formatNational();
  } else {
    return number;
  }
};

export const phoneNumberValidate = (number: string) => {
  try {
    const phoneNumber = parsePhoneNumber(number, "US");
    return phoneNumber?.isValid();
  } catch (error) {
    return false;
  }
};

export const phoneNumberFormatE164 = (number: string) => {
  const phoneNumber: any = parsePhoneNumber(number, "US");
  if (phoneNumber?.isValid()) {
    return phoneNumber.number;
  } else {
    return null;
  }
};

export function formatDateToTime(dateString: Date) {
  const date = new Date(dateString);
  const options: any = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: any) => reject(error);
  });

export function parseCsv<T extends Record<string, any>>(csv: string, header: true): T[];
export function parseCsv(csv: string, header?: false): string[][];
export function parseCsv<T>(csv: string, header: boolean = false): T[] | string[][] | undefined {
  if (!csv || !csv.length) return;

  const csvData = atob(csv);
  const parsedCsv = papa.parse<T>(csvData, { header, skipEmptyLines: true, transformHeader: (header) => header.trim().toLowerCase() });

  if (parsedCsv?.data && Array.isArray(parsedCsv.data) && parsedCsv.data.length) {
    return parsedCsv.data;
  }
}
