import { Button, Dropdown, Flex, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { RiContactsBook3Line } from "react-icons/ri";
import ContactsTable from "../components/Tables/Contacts";
import { useAddContact, useGetContacts } from "../hooks/apis/contacts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux";
import { AddContactForm } from "@data-phone/react-generic";
import ModalWrapper from "../components/Generic/modal";
import { useGetTagsByCategory } from "../hooks/apis/tags";
import { addContact } from "../redux/slices/contacts.slice";
import { useMessageToast } from "../components/Layout/DefaultLayout";
import { FaUserPlus } from "react-icons/fa";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { CiImport } from "react-icons/ci";
import ImportContacts from "../components/Contacts/ImportContacts";
import dayjs from "dayjs";

const inputSearchStyle: React.CSSProperties = {
  borderRadius: "25px",
  border: "none",
  padding: "10px",
  width: "200px",
  marginBottom: "10px",
};

const searchIconStyle: React.CSSProperties = {
  height: "20px",
  width: "20px",
  color: "#6b7280",
  cursor: "pointer",
};

const ContactsList = () => {
  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();

  const { data: contacts } = useSelector((state: RootState) => state.contacts);
  const { data: categories } = useSelector((state: RootState) => state.categories);
  const { data: tags } = useSelector((state: RootState) => state.tags);

  const [searchInput, setSearchInput] = useState("");
  const [addContactModal, setAddContactModal] = useState(false);
  const [categoryToFetch, setCategoryToFetch] = useState("uncategorized");
  const [importContactsModal, setImportContactsModal] = useState(false);

  const { refetch: getContacts, isLoading: contactsLoading } = useGetContacts();
  const { refetch: getTags, isFetching: tagsLoading } = useGetTagsByCategory();
  const { mutate: addContactHandler, isLoading: addContactLoading } = useAddContact();

  useEffect(() => {
    getTags();
  }, []);

  /**
   * Handler for when a tag is selected. Updates state with the selected
   * tag's id and fetches contacts for that tag.
   * @param {string} value The id of the selected tag.
   */
  const onTagFetch = (value: string) => {
    setCategoryToFetch(value);
  };

  const onFinish = (values: any) => {
    addContactHandler(
      {
        ...values,
        tags: values.tags.flatMap((tag) => {
          return {
            category:tag.category === "uncategorized" ? null : tag.category,
            tags: tag.tags.map((tag) => {
              return tag.value;
            })
          }
        }),
      },
      {

        onSuccess: (data) => {
          setAddContactModal(false);
          dispatch(addContact(data));
          messageApi.success("Contact added successfully");
        },
      }
    );
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      {addContactModal && (
        <ModalWrapper title="Add Contact" isModalOpen={addContactModal} setIsModalOpen={setAddContactModal}>
          <AddContactForm
            onSubmit={onFinish}
            isLoadingAddContact={addContactLoading}
            messageApi={null}
            setIsModalOpen={setAddContactModal}
            categories={categories}
            tags={tags}
            isTagsFetching={tagsLoading}
            initialNumbers={[""]}
          />
        </ModalWrapper>
      )}
      <ImportContacts setIsModalOpen={setImportContactsModal} isModalOpen={importContactsModal} />
      <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
        <Flex gap={"10px"} justify="center" align="center">
          <RiContactsBook3Line color="blue" size={35} />
          <Typography.Title>{`Contacts`}</Typography.Title>{" "}
        </Flex>
        <Flex gap={10}>
          <Button
            type="primary"
            icon={<FaUserPlus />}
            onClick={() => {
              setAddContactModal(true);
            }}
          >
            Add Contact
          </Button>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Import",
                  icon: <CiImport />,
                  onClick: () => setImportContactsModal(true),
                },
              ],
            }}
          >
            <Button style={{ backgroundColor: "lightblue" }} icon={<HiOutlineDotsHorizontal />}></Button>
          </Dropdown>
        </Flex>
      </Flex>
      <Input
        style={{
          ...inputSearchStyle,
        }}
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        size="middle"
        placeholder="Search"
        prefix={<IoSearch style={searchIconStyle} />}
      />
      <ContactsTable contacts={[...contacts].sort((a, b) => dayjs(b.created_at).unix() - dayjs(a.created_at).unix())} loading={contactsLoading} searchInput={searchInput} />
    </div>
  );
};

export default ContactsList;
