import { useState } from "react";
import { Flex, Modal, Space, Table, Typography } from "antd";
import type { TableProps } from "antd";
import { CiEdit } from "react-icons/ci";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteTagSlice, Tag } from "../../redux/slices/tags.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import ModalWrapper from "../Generic/modal";
import { TagForm } from "../Forms/Tags";
import { MdDeleteOutline } from "react-icons/md";
import { useDeleteTag } from "../../hooks/apis/tags";
import { useMessageToast } from "../Layout/DefaultLayout";

const { confirm } = Modal;

type ColumnsType<T extends object> = TableProps<T>["columns"];

const TagsTable = ({ tags, loading, searchInput }: { tags: Tag[]; loading: boolean; searchInput: string }) => {
  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();

  const { mutate: deleteTag } = useDeleteTag();
  const { data: categories } = useSelector((state: RootState) => state.categories);

  const [editData, setEditData] = useState<Tag | null>(null);
  const [editTagModal, setEditTagModal] = useState(false);

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure you want to delete this tag?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteTag(id, {
          onSuccess: () => {
            dispatch(deleteTagSlice(id));
            messageApi.success("Tag deleted successfully");
          },
        });
      },
    });
  };
  const columns: ColumnsType<Tag> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => (
        <Flex gap={"10px"} align={"center"}>
          {" "}
          <Typography.Text strong>{name}</Typography.Text>
        </Flex>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (_, { category }) => (
        <Typography.Text>{categories.find((cat) => cat.name === category) ? categories.find((cat) => cat.name=== category)?.name : "Uncategorized"}</Typography.Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <CiEdit
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditTagModal(true);
              setEditData(record);
            }}
          />
          <MdDeleteOutline
            style={{ cursor: "pointer" }}
            onClick={() => {
              showDeleteConfirm(record.name);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {editData && editTagModal && (
        <ModalWrapper title="Edit Tag" isModalOpen={editTagModal} setIsModalOpen={setEditTagModal}>
          <TagForm editData={editData} category={editData?.category} setIsModal={setEditTagModal} />
        </ModalWrapper>
      )}
      <Table
        bordered={false}
        loading={loading}
        scroll={{ y: 450 }}
        style={{ width: "100%", height: "500px" }}
        columns={columns}
        pagination={{
          pageSize: 20,
        }}
        dataSource={[...tags].filter((tag) => tag.name.toLowerCase().includes(searchInput.toLowerCase()))}
      />
    </div>
  );
};

export default TagsTable;
