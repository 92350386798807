import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Contact {
  id?: string;
  account_id?: string;
  user_id?: string;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  addresses: Address[];
  phones: Phone[];
  emails: Email[];
  tags?: {
    tag: string;
    contact_id: string;
  }[];
  is_private: boolean;
  provider_id: string | null;
  contact_key?: Number;
  updated_at?: string;
  created_at?: string;
}

export interface ImportContact {
  first_name: string;
  last_name: string;
  middle_name: string;
  address: Address[];
  phone: string[];
  email: string[];
  tag: string[];
}

export interface Address {
  address1: string;
  address2?: string;
  city?: string;
  ZIP?: string;
}

export interface Phone {
  phone_number: string;
}

export interface Email {
  email: string;
}

export interface ContactsState {
  data: Contact[];
}

const initialState: ContactsState = {
  data: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, action: PayloadAction<Contact[]>) => {
      action.payload.forEach((contact) => {
        if (!state.data.find((c) => c.id === contact.id)) {
          state.data.push(contact);
        }
      });
    },
    addContact: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload);
    },
    updateContactSlice: (state, action: PayloadAction<any>) => {
      state.data = state.data.map((contact) => (contact.id == action.payload.id ? action.payload : contact));
    },
    deleteContactSlice: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((contact) => contact.id !== action.payload);
    },
  },
});

export const { setContacts, addContact, updateContactSlice, deleteContactSlice } = contactsSlice.actions;

export default contactsSlice.reducer;
