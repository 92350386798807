import { Button, Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useMessageToast } from "../Layout/DefaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { addTagSlice, Tag, updateTagSlice } from "../../redux/slices/tags.slice";
import { useAddTag, useUpdateTag } from "../../hooks/apis/tags";

export const TagForm = ({ category: category_name, setIsModal, editData }: { setIsModal: Function; editData?: Tag; category: string | null }) => {
  const dispatch: AppDispatch = useDispatch();

  const { mutate: addTagHandler, isLoading: isLoadingAdd } = useAddTag();
  const { mutate: updateTagHandler, isLoading: isLoadingUpdate } = useUpdateTag();

  const [category, setCategory] = useState(category_name || null);

  const { data: tags } = useSelector((state: RootState) => state.tags);
  const { data: categories } = useSelector((state: RootState) => state.categories);
  const { data: user } = useSelector((state: RootState) => state.user);
  const { messageApi } = useMessageToast();
  const [form] = Form.useForm();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
    }
  }, []);

  const onFinish: FormProps<Tag>["onFinish"] = (values) => {
    if (!editData) {
      addTagHandler(
        {
          name: values.name,
          account_id: user?.accountID,
          category: category,
        },
        {
          onSuccess: (data) => {
            dispatch(addTagSlice(data));
            setIsModal(false);
            messageApi.success("Tag added successfully");
          },
        }
      );
      return;
    } else {
      updateTagHandler(
        {
          name: editData.name,
          data: {
            account_id: user?.accountID,
            category: category || null ,
            name: values.name,
          },
        },
        {
          onSuccess: (data) => {
            dispatch(updateTagSlice({
              data:{...data},
              name:editData.name
            }));
            setIsModal(false);
            messageApi.success("Tag updated successfully");
          },
        }
      );
      return;
    }
  };

  return (
    <Form style={{ padding: "10px" }} name="basic" form={form} labelCol={{ span: 4 }} onFinish={onFinish}>
      <Form.Item<Tag> name="name" label="Name" rules={[{ required: true, message: "Please input tag name" }]}>
        <Input width={"80%"} placeholder="Tag Name" />
      </Form.Item>
      <Form.Item<Tag> name="category" label="Category" initialValue={category}>
        <Select onChange={(value) => setCategory(value)} defaultValue={category} style={{ width: "100%" }} placeholder="Select Category" allowClear>
          {categories?.map((category) => (
            <Select.Option key={category.name} value={category.name}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button style={{ width: "100%" }} type="primary" htmlType="submit" disabled={isLoadingAdd} loading={isLoadingAdd || isLoadingUpdate}>
          {editData ? "Update Tag" : "Add Tag"}
        </Button>
      </Form.Item>
    </Form>
  );
};
