import Contacts from "../pages/contacts";
import Categories from "../pages/categories";
import Tags from "../pages/tags";


const coreRoutes = [
  {
    path: "/",
    title: "Contacts",
    component: Contacts,
  },
  {
    path: "/categories",
    title: "Categories",
    component: Categories,
  },
  {
    path: "/tags",
    title: "Tags",
    component: Tags
  }
];


const routes = [...coreRoutes];
export { routes };
