import { Avatar, Flex, List, Menu, MenuProps } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { HeadingText } from "../Generic/Typography";
import { useMatch, useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { RiContactsBook2Fill } from "react-icons/ri";
import { FaTags } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { CiMenuBurger } from "react-icons/ci";

const contentStyle: React.CSSProperties = {
  height: "100%",
  borderRight: "1px solid rgb(223, 226, 232)",
  background: "#fff",
};

const cardStyle = {
  border: "none",
  padding: "10px",
  borderRadius: "6px",
  cursor: "pointer",
};

const ChatSideBarItem: React.CSSProperties = {
  width: "100%",
  borderRadius: 6,
};

const SideBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Function;
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState("1");

  const matchTags = useMatch("/tags");
  const matchCategories = useMatch("/categories");

  useEffect(() => {
    if (matchTags) setActive("2");
    else if (matchCategories) setActive("3");
    else setActive("1");
  }, []);

  const menu: MenuProps["items"] = [
    {
      key: "1",
      icon: <RiContactsBook2Fill color="blue" />,
      label: "Contacts",
      onClick: () => {
        setActive("1");
        navigate("/");
      },
    },
    {
      key: "2",
      icon: <FaTags color="blue" />,
      label: "Tags",
      onClick: () => {
        setActive("2");
        navigate("/tags");
      },
    },
    {
      key: "3",
      icon: <MdCategory color="blue" />,
      label: "Categories",
      onClick: () => {
        setActive("3");
        navigate("/categories");
      },
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={contentStyle}
    >
      <div style={{ textAlign: "center", marginTop: "15px" }}>
        <CiMenuBurger
          strokeWidth={2}
          onClick={() => setCollapsed(!collapsed)}
          cursor={"pointer"}
          color="blue"
        />
      </div>
      <Menu
        activeKey={active}
        selectedKeys={[active]}
        theme="light"
        mode="inline"
        items={menu}
      />
    </Sider>
  );
};

export default SideBar;
