import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user.slice"
import contactsReducer from "./slices/contacts.slice"
import categoriesReducer from "./slices/categories.slice"
import tagsReducer from "./slices/tags.slice"



export const store = configureStore({
  reducer: {
    user:userReducer,
    contacts:contactsReducer,
    categories: categoriesReducer,
    tags: tagsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
