import { api } from "@data-phone/react-resource-server-auth";
import { useMutation, useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { setTagCategoriesSlice, TagCategory } from "../../redux/slices/categories.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";

interface responseType {
  data: TagCategory[];
  status: string;
}
const getCategoriesApi = async () => {
  const response = await api.get(`
        ${process.env.REACT_APP_CONTACT_API_URL}/category`);
  return response.data;
};

const addCategoryApi = async (data: Partial<TagCategory>) => {
  const response: AxiosResponse<TagCategory> = await api.post(`${process.env.REACT_APP_CONTACT_API_URL}/category`, data);
  return response.data;
};

const updateCategoryApi = async (name: string, data: Partial<TagCategory>) => {
  const response = await api.put(`${process.env.REACT_APP_CONTACT_API_URL}/category/${name}`, data);
  return response.data;
};

const deleteCategoryApi = async (name: string) => {
  const response = await api.delete(`${process.env.REACT_APP_CONTACT_API_URL}/category/${name}`);
  return response.data;
};

export const useGetCategories = () => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<TagCategory[], Error>("categories", getCategoriesApi, {
    onSuccess(data) {
      dispatch(setTagCategoriesSlice(data));
    },
    enabled: false,
  });
};

export const useAddCategory = () => {
  return useMutation((data: Partial<TagCategory>) => addCategoryApi(data));
};

export const useUpdateCategory = () => {
  return useMutation((category: { name: string; data: Partial<TagCategory> }) => updateCategoryApi(category.name, category.data));
};

export const useDeleteCategory = () => {
  return useMutation((name: string) => deleteCategoryApi(name));
};
